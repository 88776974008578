import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, ElementRef, HostListener, inject
  , input, OnDestroy, OnInit, signal, ViewChild
 } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ItmToolbarComponent } from '@itm/components/toolbar';
import { ApiHttpService, ILayoutConfig, ItmToastrService, ItmUserService, LayoutService } from '@itm/services';
import { ActionName, IListAction, IListColumn } from '@itm/types';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { 
  GridModule, SearchService, ToolbarService, ExcelExportService, PdfExportService, FilterService, SortService, GroupService, PageService
  , ColumnChooserService, ColumnMenuService, FailureEventArgs, FilterSettingsModel, GridComponent, GroupSettingsModel
  , ToolbarItems, SelectionSettingsModel, ReorderService, ResizeService, SearchSettingsModel, ExcelQueryCellInfoEventArgs, 
  PageSettingsModel,
  QueryCellInfoEventArgs,
  row,
  ExcelExportProperties

} from '@syncfusion/ej2-angular-grids';
import { SelectEventArgs, SelectingEventArgs, SidebarModule, TabModule } from '@syncfusion/ej2-angular-navigations';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import { CacActionService } from 'app/modules/admin/cac/cac.action.service';
import { catchError, Subject, takeUntil, throwError } from 'rxjs';
import { SideBarManagerComponent } from '../popup/manager/sidebar-manager.component';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { ActionStatus, IActions } from '../utilities';
import { CacNotifyService } from '../../shared/services/cac.notify.service';
import { ActionCentreKanbanComponent } from './action-centre-kanban.component';
import { ItmNavigationService } from '@itm/services/itm-navigation.service';
import { EXPORT_FILE_NAME } from '@itm/constants';
import { FuseConfigService } from '@fuse/services/config';
import { AppEmbeddedService } from '@itm/services/app.embedded.service';

@Component({
    selector: 'action-centre-list',
    templateUrl: './action-centre-list.component.html',
    styleUrls: ['./action-centre-list.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.Default,
    imports: [RouterOutlet, CommonModule, GridModule, ItmToolbarComponent, FuseAlertComponent, MatInputModule, FuseDrawerComponent
      , DropDownListModule, SidebarModule, SideBarManagerComponent, ActionCentreKanbanComponent, TabModule],
    providers: [SearchService,
      ToolbarService,
      ExcelExportService,
      PdfExportService,
      FilterService,
      SortService,
      GroupService,
      PageService,
      ColumnChooserService,
      ColumnMenuService,
      ReorderService,
      ResizeService
    ],
})
export class ActionCentreListComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const innerWidth = event.target.innerWidth;
    const innerHeight = event.target.innerHeight;
    this.gridHeight = innerHeight - 150;
    this.kanbanHeight = innerHeight - 180;

    let parentDiv = document.getElementById('gridWrapper');
    if (parentDiv) {
      (parentDiv as HTMLElement).style.height = this.gridHeight + 'px';
    }
    
    parentDiv = document.getElementById('kanbanWrapper');
    if (parentDiv) {
      (parentDiv as HTMLElement).style.height = this.kanbanHeight + 'px';
    }
  }

  
  @ViewChild('kanban') kanban?: ActionCentreKanbanComponent;
  
  gridHeight: any = 760;
  listGridHeightToReduce: any = 170
  kanbanHeight: any = 740;
  
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  private readonly _layoutService = inject(LayoutService);
  private readonly _cacService = inject(CacActionService);
  private readonly _toastr = inject(ItmToastrService);
  private readonly _fuseConfirmationService = inject(FuseConfirmationService);
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _titleService = inject(Title);
  private readonly _itmUserService = inject(ItmUserService);
  private readonly _http = inject(ApiHttpService);
  private readonly _cacNotifyService = inject(CacNotifyService);
  private readonly _itmNavigationService = inject(ItmNavigationService);
  private readonly _appEmbeddedService = inject(AppEmbeddedService);

  pageKey = signal<string>('');
  layoutConfig = signal<ILayoutConfig>(undefined);
  tabs = computed(() => this.layoutConfig()?.tabs);
  toolbar = computed(() => this.layoutConfig()?.toolbar??undefined);
  breadcrumb = computed(() => this.layoutConfig()?.breadcrumb);
  gridPage = input.required<string>({ alias: 'page' });  
  listColumns = signal<IListColumn[]>(undefined);
  searchableColumns = computed(()=> this.listColumns().filter(x=>x.searchable == null || x.searchable ==true ).filter(x=>x.field != null).map(x=>x.field));
  exportFileName: any;

  @ViewChild('actionCentreDetailsSidebar') actionCentreDetailsSidebar: FuseDrawerComponent;
  @ViewChild('grid') public grid?: GridComponent;
  
  pageSettings!: PageSettingsModel;
  selectionOptions?: SelectionSettingsModel;
  urlAdapterData!: DataManager;
  loadingIndicator!: any;
  toolbarOptions!: ToolbarItems[];
  toolbarType: any = 'list';
  contactId: any = null;
  description: string = '';
  dataStoreKey!:string;
  // @Input('page') gridPage = '1';
  openMode: 'full' | 'slider' = 'full';
  selectedRowIndex: any = null;
  allowFiltering: boolean = true;

  showListView: boolean = true;

  parentEmbeddedAppTypeId: any = 0  
  occuredDateTimeText: any = 'Created Date & Time';

  constructor(private router: Router, private _fuseConfigService: FuseConfigService
    // , private host: ElementRef
  ) {
    effect(() => {
      // console.log('listColumns', this.listColumns());
    });
  }

  ngOnInit(): void {
    this.gridHeight = window.innerHeight - this.listGridHeightToReduce;

    this._appEmbeddedService.appEmbeddedType$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(x => {
      // console.log(x);
      if (x) {
        this.parentEmbeddedAppTypeId = x.parent;
      }
    });

    this.itmOnLayoutInit();

    this.initGrid();

    this._layoutService.toolbarButtonClick$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(x => {
      // console.log(x);
      if (x) {
        this.onToolbarButtonClick(x);
      }
    });

    this._layoutService.toolbarSwitchClick$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(x => {
      if(x){
        this.showListView = x.checked;
      }
    });
    
    this._cacNotifyService.receiveNotification$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(x => {
      if(x){
        this.onRefreshClicked();
      }
    });
    
    
    this._cacNotifyService.sendOpenSidebar$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(x => {
      // console.log(x)
      if(x){
        const data = JSON.parse(x);
        this.onClickAction(data.actionId)
      }
    });
    
    ////Open sidebar 
    const id = this._activatedRoute.snapshot.paramMap.get('id');
    if(id){
      this.openSidebar(id)
    }
  }

  setLayout(layout: string): void {
    // Clear the 'layout' query param to allow layout changes
    this.router
        .navigate([], {
            queryParams: {
                layout: layout,
            },
            queryParamsHandling: 'merge',
        })
        .then(() => {
            // Set the config
            this._fuseConfigService.config = { layout };
        });
  }

  public onToolbarButtonClick(e: any): void {
    const actionName = e;
    if (actionName === ActionName.DoNotEscalate) {
      this.onDoNotEscalateClicked();
      return;
    }
    else if (actionName === ActionName.Remind) {
      this.onRemindClicked();
      return;
    }
    else if (actionName === ActionName.Archive) {
      this.onArchiveClicked();
      return;
    }
    else if (actionName === ActionName.Refresh) {
      this.onRefreshClicked();
      return;
    }
    else if (actionName === ActionName.Close) {
      this.onCloseClicked();
      return;
    }
    else if (actionName == 'XLSX') {
      this.onXlsxExportClicked();
      return;
    }
    else if (actionName == 'CSV') {
      this.onCsvExportClicked();
      return;
    }
    // else if (actionName == 'PDF') {
    //   this.onPdfExportClicked();
    //   return;
    // }
  }

  onCsvExportClicked(): void {
    // 'Grid_csvexport' -> Grid component id + _ + toolbar item name    
    const excelExportProperties: ExcelExportProperties = {
      columns: this.getExportedColumns(),
      fileName: this.exportFileName + '.csv',
    };
    (this.grid as GridComponent).csvExport(excelExportProperties);
  }

  onXlsxExportClicked(): void {
    // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
    const excelExportProperties: ExcelExportProperties = {
      columns: this.getExportedColumns(),
      fileName: this.exportFileName + '.xlsx',
    };
    (this.grid as GridComponent).excelExport(excelExportProperties);
  }
  
  getExportedColumns(){
    const excelExportColumns: any = [];
    this.listColumns()?.filter(f => f.allowExport).forEach(col => {
      excelExportColumns.push({
        field: col.field,
        width: col.width,
        textAlign: col.align,
        headerText: col.headerText,
      });
    });
    return excelExportColumns;
  }

  onDoNotEscalateClicked(){
    if(this.showListView){
      const selectedRows = (this.grid as GridComponent).getSelectedRecords().map(x => x['id']);
      if(selectedRows && selectedRows.length == 0){
        this._toastr.showSuccess('Select record(s) first.');
        return;
      }
      if (selectedRows.length > 0) {
        const confirmationMessage = `Are you sure you want to make 'DO NOT ESCALATE' ?`;
        const confirmation = this._fuseConfirmationService.open(
          {
            title: 'Confirmation',
            message: confirmationMessage,
            dismissible: true,
            panelClass: "donotescalate-dialog-panel",
            icon: {
              show: true,
              color: 'info'
            },
            actions: {
              confirm: {
                show: true,
                label: 'Confirm',
                // color: 'primary',

              },
              cancel: {
                show: true,
                label: 'Cancel'
              }
            }
          }
        );
        confirmation.afterClosed().subscribe((result) => {
          // If the confirm button pressed...

          const postData = [];
          selectedRows.forEach(row => {
            postData.push({
              id: row, reason: 'Bulk action taken...'
            })
          });
          if (result === 'confirmed') {
            this._cacService.doNotEscalate(postData).subscribe((resp: any) => {
              if(resp.data.result == true){
                this.grid.refresh();
                this._toastr.showSuccess('Successfully applied do not escalate.');
              }
            })
          }
        });
      }
    }
  }

  onRemindClicked(){
    if(this.showListView){
      const selectedRows = (this.grid as GridComponent).getSelectedRecords().map(x => x['id']);
      if(selectedRows && selectedRows.length == 0){
        this._toastr.showSuccess('Select record(s) first.');
        return;
      }
      if (selectedRows.length > 0) {
        const confirmationMessage = `Are you sure you want to make 'REMIND' ?`;
        const confirmation = this._fuseConfirmationService.open(
          {
            title: 'Confirmation',
            message: confirmationMessage,
            dismissible: true,
            panelClass: "reminder-dialog-panel",
            icon: {
              show: true,
              color: 'info'
            },
            actions: {
              confirm: {
                show: true,
                label: 'Confirm',

              },
              cancel: {
                show: true,
                label: 'Cancel'
              }
            }
          }
        );
        confirmation.afterClosed().subscribe((result) => {
          // If the confirm button pressed...
          if (result === 'confirmed') {
            this._cacService.remind(selectedRows).subscribe((resp: any) => {
              if(resp.data.result == true){
                this.grid.refresh();
                this._toastr.showSuccess('Successfully applied reminder.');
              }
            })
          }
        });
      }
    }
  }

  onArchiveClicked(){
    if(this.showListView){
      const selectedRows = (this.grid as GridComponent).getSelectedRecords().map(x => x['id']);
      if(selectedRows && selectedRows.length == 0){
        this._toastr.showSuccess('Select record(s) first.');
        return;
      }

      if (selectedRows.length > 0) {
        const confirmationMessage = `Are you sure you want to make 'ARCHIVE' ?`;
        const confirmation = this._fuseConfirmationService.open(
          {
            title: 'Confirmation',
            message: confirmationMessage,
            panelClass: "archive-dialog-panel",
            dismissible: true,
            icon: {
              show: true,
              color: 'info'
            },
            actions: {
              confirm: {
                show: true,
                label: 'Confirm',

              },
              cancel: {
                show: true,
                label: 'Cancel'
              }
            }
          }
        );
        confirmation.afterClosed().subscribe((result) => {
          // If the confirm button pressed...
          if (result === 'confirmed') {
            this._cacService.archive(selectedRows).subscribe((resp: any) => {
              if(resp.data.result == true){
                this.grid.refresh();
                this._toastr.showSuccess('Successfully applied archive.');
              }
            })
          }
        });
      }
    }
  }
  
  onRefreshClicked(): void {
    if(this.showListView){
      this.grid?.clearFiltering();
      this.grid?.clearSorting();
      this.grid?.goToPage(1);
      this.grid.refresh();
    }
    else {
      this.kanban.kanbanctrl?.refresh();
    }
  }

  onCloseClicked(): void {
    this._itmNavigationService.navigate('/');
  }

  customiseCell(args: QueryCellInfoEventArgs) {
    if (args.column.field == 'statusText') {
        if (args.data['status'] === ActionStatus.New) {
          args.cell.classList.add('status-new');
        } 
        else if (args.data['status'] === ActionStatus.AutoResolve) {
          args.cell.classList.add('status-autoresolve');
        } 
        else if (args.data['status'] === ActionStatus.Archived) {
          args.cell.classList.add('status-archived');
        } 
        else if (args.data['status'] === ActionStatus.DoNotEscalate) {
          args.cell.classList.add('status-donotescalate');
        } 
        else if (args.data['status'] === ActionStatus.Escalated) {
          args.cell.classList.add('status-escalated');
        } 
        else if (args.data['status'] === ActionStatus.Reminder) {
          args.cell.classList.add('status-reminder');
        } 
        else if (args.data['status'] === ActionStatus.Resolved) {
          args.cell.classList.add('status-resolved');
        } 
        else {
          args.cell.classList.add('status-color');
        }
    }
  }

  onListActionPerformed(action: IListAction) {
    if (action.actionName == 'refresh') {
      this.grid.refresh();
    }
  }

  // setGridHeight() {
  //   const pageLayout = document.getElementsByClassName('itm-page-layout')[0];
  //   const gridWrapperDiv = document.getElementsByClassName('gridWrapper')[0];
  //   // (parentDiv as HTMLElement).style.width = (this.widthTextBox as NumericTextBoxComponent).element.value + 'px';
  //   (gridWrapperDiv as HTMLElement).style.height = (((pageLayout.clientHeight - 30) / this.height()) * 100) + 'px';
  // }

  setEjsGridToolbar(): void {
    this.toolbarOptions = ['ColumnChooser', 'Search'];
  }

  itmOnLayoutInit(): void {
    this._activatedRoute.data.subscribe(routeData => {
      const pageKey = routeData['pageKey'];
      if (pageKey) {
        this.dataStoreKey = pageKey;
        this.pageKey.set(pageKey);
      }
      const layoutConfig: ILayoutConfig = routeData['config'];
      if (layoutConfig) {
        if(layoutConfig.components){
          layoutConfig.components.sort((a, b) => {
            return a.sortOrder - b.sortOrder;
          })
          this.exportFileName = layoutConfig.components[0]?.exportFileName ?? EXPORT_FILE_NAME;
          this.listColumns.set(layoutConfig.components[0].listColumns)
        }
        this.layoutConfig.set(layoutConfig);

        this.hideCloseBtnByEmbeddedAppType();
      }
    });
    this._titleService.setTitle(this.layoutConfig()?.pageTitle);
    // this._changeDetectorRef.markForCheck();
  }

  public filterSettings?: FilterSettingsModel;
  public searchSettings?: SearchSettingsModel;
  public groupOptions?: GroupSettingsModel;
  initGrid() {
    const gridPage = this.gridPage() == undefined || null ? 1 : Number(this.gridPage());

    this.pageSettings = { pageSizes: [15, 30, 45, 'All'], pageSize: 15, enableQueryString: false, currentPage: gridPage };
    this.selectionOptions = { checkboxOnly: true, mode: 'Row', type: 'Single' }

    this.loadingIndicator = { indicatorType: 'Spinner' };
    this.groupOptions = { showDropArea: false };
    this.filterSettings = { 
      type: 'Excel', 
      mode:'OnEnter', 
      itemsCount: 10, 
      enableInfiniteScrolling: true, 
      loadingIndicator: 'Spinner',
      showFilterBarStatus: true, 
      immediateModeDelay: 1500 
    };
    this.searchSettings = {fields: this.searchableColumns()};
    const composedURL = `${this._http.GET_API_URL()}cac/action/list/${this.parentEmbeddedAppTypeId}`;
    this.urlAdapterData = new DataManager({
      url: composedURL,
      headers: [{ 'Authorization': `Bearer ${this._itmUserService.getAccessTokenLocalStorage()}` }],
      adaptor: new UrlAdaptor(),      
    });

    // this.setGridHeight();
  }

  dataBound() {
    ////TODO, producing NaN
    let changedHeight = this.grid.element.getBoundingClientRect().height - parseInt(this.grid.height as string);
    // console.log(changedHeight);

    //dynamically setting the grid height including header and footer 
    this.grid.height = parseInt(this.grid.height as string) - changedHeight;

    if (this.openMode === 'slider'){
      if(this.selectedRowIndex){
        this.selectThisRow(this.selectedRowIndex);
      }
    }
  }
  
  excelQueryCellInfo (args: ExcelQueryCellInfoEventArgs) {  
    const val: any = args.value;
    if (args.column.type === 'datetime') {
    //   let p: any = new Intl.DateTimeFormat('en',{
    //     year:'numeric',
    //     month:'2-digit',
    //     day:'2-digit',
    //     hour:'2-digit',
    //     minute:'2-digit',
    //     second:'2-digit',
    //     hour12: true
    //   }).formatToParts(val).reduce((acc, part) => {
    //       acc[part.type] = part.value;
    //       return acc;
    //   }, {});
      
      // args.value =  `${p.day}/${p.month}/${p.year} ${p.hour}:${p.minute}:${p.second}`; 
      args.value =  val.toLocaleString("en-NZ", { hour12: false }); 
    }
    else if (args.column.type === 'date') {
      let p: any = new Intl.DateTimeFormat('en',{
        year:'numeric',
        month:'2-digit',
        day:'2-digit',
        hour:'2-digit',
        minute:'2-digit',
        hour12: true
      }).formatToParts(val).reduce((acc, part) => {
          acc[part.type] = part.value;
          return acc;
      }, {});
      
      args.value =  `${p.day}/${p.month}/${p.year}`; 
    }
  }

  fail(e: FailureEventArgs) {
    // handle the error.
    console.log('grid fail ==>', e.error);
  }

  selectThisRow(rowIndex){
    const row = this.grid.getRowByIndex(rowIndex);
    if (row) {
      document.querySelectorAll('.e-row').forEach(row => row.classList.remove('selected-row'));
      row.classList.add('selected-row');
    }
  }

  beforeDataBound(args: any): void {
    try {
      if (this.openMode === 'slider') {
        let gridData = args.result;
        let newFormId = this.getIdFromUrl();
        const selectedIndex = gridData.findIndex((r: any) => r.pId === newFormId);
        
        if (selectedIndex !== -1) {
          this.selectedRowIndex = selectedIndex;
        } else {
          this.selectedRowIndex = null;
        }
      }
    } catch (error) {
      this.selectedRowIndex = null;
    }
  }

  getIdFromUrl() {
    try {
      if (this.openMode === 'slider') {
        let url = window.location.href;
  
        let urlObj = new URL(url);
        let path = urlObj.pathname;
  
        let parts = path.split('/');
        let newFormId = parts[parts.length - 1]?.trim();
  
        return newFormId;
      }
    } catch (error) {
      return "";
    }
  }

  ngOnDestroy(): void {
    this._layoutService.dataStoreRemoveItem(this.dataStoreKey);
  }
  
  handleListActionButton(e: CustomEvent): void {
    // this.onActionButton(e);
  }

  onActionButton(e: any){
  }

  selectedAction = signal(undefined);
  onClickAction(id: any){
    const url = `${window.location.href}/${id}`
    window.history.pushState({ path: url }, '', url);
    this.openSidebar(id);
  }
  
  closeClick() {
    this.grid?.refresh();
    this.actionCentreDetailsSidebar?.close();
  }

  drawerOpen(isOpened: any){
    this._cacNotifyService.setSidebarOpenedStatus(isOpened);
    if(isOpened == false){
      const url = `${window.location.origin}/cac/action-centre`
      window.history.pushState({ path: url }, '', url);
    }
  }

  openSidebar(id: any){
    this._cacService.getActionById<IActions>(id).subscribe((resp: any) => {
      const { result } = resp.data.data; 
      this.selectedAction.set(result);
      this.actionCentreDetailsSidebar?.toggle();
    },
    (error: any) => {
      const url = `${window.location.origin}/cac/action-centre`
      window.history.pushState({ path: url }, '', url);
      this._toastr.showError(`Something went wrong... Error: ${error}`);
    });
  }

  
  public clickTabs(e: SelectEventArgs) { 
    // console.log(e)
    this.showListView = e.selectedIndex == 0;
    this.toolbar()?.buttons.map((m: any) => {
      if(['DoNotEscalate', 'Remind', 'Archive', 'Export'].includes(m.name))
        m.visible = this.showListView;
    });
  } 

  public select(e: SelectingEventArgs) { 
    // console.log(e)
  } 

  public actionBegin(args: any): void {
    if (args.requestType === 'paging') {
      // Prevent default behavior or URL modification
      this.grid.goToPage(args.currentPage);
      this.router.navigate([], {
        queryParams: {}, // Clear query parameters
        replaceUrl: true,
      });
    }
  }

  hideCloseBtnByEmbeddedAppType(){
    if(this.parentEmbeddedAppTypeId > 0){
      this.toolbar()?.buttons.map((m: any) => {
        if(['Close'].includes(m.name))
          m.visible = false;
      });
    }
  }
  
}
