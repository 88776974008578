<div class="inner-container" id="innerContainer">
    <div class="py-3 text-base">{{subTitle}}</div>
    <div class="body">
      <div class="body-container">
        <table class="table-fixed border-collapse border border-slate-400 w-full">
            <tbody>
              <tr>
                <td>Equipment No</td>
                <td>{{actionData()?.additional?.equipments[0]?.equipmentNo}}</td>
              </tr>
              <tr>
                <td>Branch</td>
                <td>{{actionData()?.branchName}}</td>
              </tr>
              <tr>
                <td>Hire No</td>
                <td>{{actionData()?.hireNo}}</td>
              </tr>
              <tr>
                <td>Company</td>
                <td>{{actionData()?.companyName}}</td>
              </tr>
              <tr>
                <td>Site</td>
                <td>{{actionData()?.siteName}}</td>
              </tr>
              <tr>
                <td>Category</td>
                <td>{{actionData()?.additional?.category}}</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{{actionData()?.additional?.description}}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>On-Hire</td>
              </tr>
              <tr>
                <td>Utilisation</td>
                <td>{{actionData()?.additional?.utilisation}}</td>
              </tr>
              <tr>
                <td>Alert Date and Time</td>
                <td>{{actionData()?.occuredDateTime | date: 'dd/MM/yyyy hh:mm a'}}</td>
              </tr>
              <tr>
                <td style="vertical-align: top;">Geolocation at alert</td>
                <td>
                  <app-map [actionData]="actionData()">

                  </app-map>
                </td>
              </tr>
              <tr>
                <td>Alert Status</td>
                <td class="text-red-500">Action required</td>
              </tr>
            </tbody>
        </table>
      </div>
      <div class="action-required-container">
          <div class="title">Action Required</div>
          <div class="sec-1">
            @if (actionData()?.actionTypeId == ActionType.Alert) {
              @if(actionData()?.actionSubTypeId== ActionAlertSubType.OutSideNotHire){
                Please update the status of the equipment in Baseplan within 24 hours of receiving this alert.
              }
              @else if(actionData()?.actionSubTypeId== ActionAlertSubType.OutSideStoodDownUsed){
                Please contact the customer and advice usage has been reported on their stood down item. Remove stand-down if required.
              }
              @else if(actionData()?.actionSubTypeId== ActionAlertSubType.OutSideOffHiredUsed){
                Please contact the customer and advice usage has been reported on an off-hired item. Update Baseplan if required.
              }
              @else if(actionData()?.actionSubTypeId== ActionAlertSubType.InSideOnHire){
                Please organise delivery of equipment or complete the return of hire.
              }
            }
          </div>
          
          @if (actionData()?.actionTypeId == ActionType.Alert) {
            @if(actionData()?.actionSubTypeId== ActionAlertSubType.OutSideOffHiredUsed){
              <div class="sec-2">
                    All alerts will be escalated to the Regional Operations Manager in the monthly exception report.
              </div>
            }
          }
          <div class="sec-3">
              This alert will auto-resolve once action is taken in Baseplan.
          </div>
      </div>
    </div>
    
    <div class="reasons" *ngIf="showReasonSec">
        <ejs-textarea cssClass="e-outline" maxLength="500" placeholder='Reason' floatLabelType="Auto"
            id="reason" [(ngModel)]="postData.reason">
        </ejs-textarea>
        <label class="requiredLabel">*</label>
    </div>

    <div class="actions">
        <button class="btn btn-remind-hour" (click)="onRemindHourClick($event)" [disabled]="actionData()?.hasActiveReminder">
            Remind In An Hour
        </button>
        <button class="btn btn-do-not-escalate" (click)="onDoNotEscalateClick($event)" [disabled]="actionData()?.hasAciveDoNotEscalate">
            Do Not Escalate
        </button>
    </div>
</div>
